
















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { PostsViewModel } from '../../../viewmodels/posts-viewmodel'

@Observer
@Component({
  components: {
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostLists extends Vue {
  @Inject() vm!: PostsViewModel
  loadMore() {
    if (this.vm.posts?.canLoadMorePost) {
      this.vm.posts.loadMore()
    }
  }

  syncFollowState(postStore) {
    const postStoreWithSameOwners =
      this.vm.posts?.posts.filter((item) => item.post.profile.id === postStore.post.profile.id) || []
    postStoreWithSameOwners.map((item) => (item.post.isFollowing = postStore.post.isFollowing))
    this.$forceUpdate()
  }
}
